form.credit-input-form {
	width: 100%;
}

h2.payment-header {
	width: 100%;
	text-align: center;
	color: #FFFFFF;
	background-color: #5962AA;
	height: 74px;
	line-height: 74px;
	margin: 0;
	font-family: ヒラギノ角ゴシック;
}

p.payment-top-msg {
	font-size: 17px;
	color: #707070;
	font-family: Hiragino Kaku Gothic ProNs;
	height: 29px;
	line-height: 29px;
	position: relative;
	padding: 0 65px;
	text-align: center;
}


p.payment-top-msg:before {
	position: absolute;
	top: calc(50% - 1px);
	left: 0;
	width: 100%;
	height: 2px;
	content: '';
	background: #B2BAFC;
}

p.payment-top-msg span {
	position: relative;
	padding: 0 1em;
	background: #fff;
	font-family: ヒラギノ角ゴシック;
}

div.payment-box {
	width: 50%;
	min-width: 350px;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
}

@media screen and (max-width: 450px) {
	div.payment-box {
		min-width: 300px;
	}
}

form.credit-input-form button.submit-button {
	border-radius: 6px;
	background-color: #5962AA;
	box-shadow: inset 0 0 0 1px rgb(50 50 93 / 10%), 0 2px 5px 0 rgb(50 50 93 / 10%), 0 1px 1px 0 rgb(0 0 0 / 7%);
	color: #fff;
	cursor: pointer;
	height: 44px;
	font-size: 22px;
	font-weight: bold;
	margin: 25px auto;
	outline: none;
	overflow: hidden;
	padding: 0;
	position: relative;
	transition: all .2s ease, box-shadow .08s ease-in;
	width: 100%;
}

form.credit-input-form button.submit-button:disabled {
	background: #AAA;
}

form.credit-input-form button.isSubmit {
	background: #8e98e0 !important;
}

div.input-box {
	width: auto;
	margin-bottom: 0.75rem;
}

.input-box label.input-label {
	margin-bottom: 0.25rem;
	font-family: Ideal Sans, system-ui, sans-serif;
	font-weight: 400;
	font-size: 0.93rem;
	color: #30313d;
}

.input-box input.input-content {
	padding: 12px 0 12px 12px;
	width: calc(100% - 12px);
	border-radius: 4px;
	border: 1px solid #e6e6e6;
	box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
}

.input-box input.input-content:focus {
	border: 1px solid #0570de;
	outline: 0;
	box-shadow: 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 hsla(210, 96%, 45%, 50%);
}

div.error-content {
	height: auto;
	margin-top: 20px;
	font-size: 14px;
	color: rgb(255, 9, 9);
}

.__myAlertModal .alert-wrapper .alert-close-icon {
	display: none;
}

.__myAlertModal .alert-wrapper {
	width: auto;
}

.__myAlertModal .alert-wrapper .alert-close-icon {
	display: none;
}

.__myAlertModal .alert-wrapper h3.alert-text {
	font-size: 15px;
	font-weight: 400;
	font-family: ヒラギノ角ゴシック, W7;
}

/* purchaseInfo.js */
div.purchaseInfo_box p.purchaseInfo_date {
	text-align: center;
	margin-top: 35px;
	margin-bottom: 5px;
	font-size: 15px;
	color: #707070;
}

div.purchaseInfo_box p.purchaseInfo_nextdate {
	margin-top: 0px;
	margin-bottom: 42px;
	text-align: center;
	font-size: 13px;
	color: #707070;
}

@media screen and (max-width: 450px) {
	div.purchaseInfo_box p.purchaseInfo_nextdate {
		margin-bottom: 15px;
	}
}

div.purchaseInfo_box div.purchaseInfo_plan_box {
	display: flex;
	margin-top: 0px;
}

div.purchaseInfo_box div.purchaseInfo_plan_box p.plan_name {
	font-size: 14px;
	text-decoration: underline;
	color: #AFADAD;
	text-decoration-color: #AFADAD;
	margin-top: 6px;
	margin-bottom: 0px;
}

div.purchaseInfo_box div.purchaseInfo_plan_box label.plan_price {
	margin: 0;
	margin-top: -10px;
	font-size: 10px;
	line-height: 10px;
	color: #AFADAD;
}

div.purchaseInfo_box div.purchaseInfo_plan_box div.purchaseInfo_img {
	width: 115px;
	height: 94px;
	display: block;
	background-image: url("../awesome-pills.png");
	background-size: contain;
	background-repeat: no-repeat;
	background-size: 80%;
	margin-top: 10px;
}

@media screen and (max-width: 450px) {
	div.purchaseInfo_box div.purchaseInfo_plan_box div.purchaseInfo_img {
		width: 80px;
		height: 60px;
	}
}

div.error-block {
	background-color: #FCE3E2;
	border: 1px solid #BB211F;
	border-left: 10px solid #BB211F;
	padding: 0px 15px;
}

div.error-block p.error-block_msg {
	color: #BB211F;
	padding: 0px auto 0px 10px;
}

div.error-block p.error-block_msg::before {
	content: "⚠️";
	padding-right: 5px;
}