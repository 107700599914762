div.error-msg-div {
    text-align: center;
    margin: 0 auto;
    color: #5962AA;
    padding: 20px;
    border-radius: 4px;
}

div.error-msg-div .error-title {
    font-size: 50px;
    font-weight: bold;
    font-family: Josefin Sans, sans-serif;
    margin-bottom: 10px;
}