/* サンクスページ */
div.thanks {
	margin: 0px auto;
	text-align: center
}

img.thanks-img {
	margin-top: 40%;
}

p.thanks-msg {
	text-align: center;
	margin: 20px 10%;
}